import React, { useState } from "react";
import whiteBrandLogo from "../../assests/images/brandLogoWhite.svg";
import { Link } from "react-router-dom";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { RoutePaths } from "../../routes/RoutesPath/RoutePaths";
import AlertModal from "../../components/common/alertModal";
import FeedbackForm from "../common/feedbackForm";
import ThankYou from "../../components/common/thankYou";

const Index = () => {
  const { home, faq, partnerWithUs, aboutUs, stores, privacy, termsCondition } =
    RoutePaths;
  const [openModal, setOpenModal] = useState(false);
  const [showThankYouPopUp, setShowThankYouPopUp] = useState(false);
  const handleOpenCloseThankyouModal = () => {
    setShowThankYouPopUp(!showThankYouPopUp);
  };
  const handleOpenCloseModal = () => {
    setOpenModal(!openModal);
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <>
      <div className="container-fluid bg-color-primary" id="footer">
        <div className="container">
          <div className="py-4">
            <div className="row align-items-center">
              <div className="col col-sm-12 col-md-3 mb-2">
                <Link to={home}>
                  {" "}
                  <img src={whiteBrandLogo} alt="specX Logo" />
                </Link>
              </div>
              <div className=" menu-item col-12 col-sm-12 col-md-6">
                <ul className="list-unstyled d-flex gap-5 m-0 justify-content-center footer-menu">
                  <li className=" text-capitalize fw-normal">
                    {" "}
                    <Link
                      className="text-decoration-none text-color-white"
                      to={home}
                    >
                      Home
                    </Link>
                  </li>
                  <li className=" text-capitalize fw-normal">
                    {" "}
                    <Link
                      className="text-decoration-none text-color-white"
                      to={partnerWithUs}
                    >
                      Our Partner
                    </Link>
                  </li>
                  <li className=" text-capitalize fw-normal">
                    {" "}
                    <Link
                      className="text-decoration-none text-color-white"
                      to={faq}
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className=" text-capitalize fw-normal">
                    {" "}
                    <Link
                      className="text-decoration-none text-color-white"
                      to={stores}
                    >
                      Store
                    </Link>
                  </li>
                  <li
                    className=" text-capitalize fw-normal text-decoration-none text-color-white cursor-pointer"
                    onClick={handleOpenCloseModal}
                  >
                    {" "}
                    Feedback
                  </li>
                </ul>
              </div>
              <div className="col col-sm-12 col-md-3">
                <div className="d-flex align-items-center justify-content-center  social-icon-box gap-3">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/invites/contact/?i=1ewn4qtkyydfx&utm_content=twe8n6u"
                  >
                    <BiLogoInstagramAlt
                      className="text-color-white footer-social-icon"
                      style={{ fontSize: "30px" }}
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/share/ymS1LnwD4aXqceCV/?mibextid=qi2Omg"
                  >
                    {" "}
                    <BsFacebook
                      className="text-color-white footer-social-icon"
                      style={{ fontSize: "24px" }}
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://youtube.com/@specxweb?si=wSwSBoI7E2ZJbqYG"
                  >
                    {" "}
                    <BsYoutube
                      className="text-color-white footer-social-icon"
                      style={{ fontSize: "25px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom border-1 " />
        {/* <div className="container">
          <div className="py-5">
            <div className="row">
              <div className="col col-sm-12 col-md-5">
                <div className='d-flex gap-3'>
                  <div className='border border-1 rounded-circle px-2 py-1 h-fit-content '>
                    <IoLocationOutline className='text-color-white' />
                  </div>
                  <div>
                    <h4 className='fs-4 fw-bold text-color-white text-capitalize'>Location</h4>
                    <p className="fs-6 text-color-white m-0">UG 2A, VIDHYAPATI COMPLEX, RACE COURSE ROAD,
                      JANZEERWALA SQUARE, INDORE, M.P., INDIA </p>
                  </div>
                </div>
              </div>
              <div className="col col-sm-12 col-md-4">
                <div className='d-flex gap-3'>
                  <div className='border border-1 rounded-circle px-2 py-1 h-fit-content '>
                    <VscMail className='text-color-white' />
                  </div>
                  <div>
                    <h4 className='fs-4 fw-bold text-color-white text-capitalize'>Email address</h4>
                    <p className="fs-6 text-color-white m-0">info@specxweb.com </p>
                  </div>
                </div>
              </div>
              <div className="col col-sm-12 col-md-3">
                <div className='d-flex gap-3'>
                  <div className='border border-1 rounded-circle px-2 py-1 h-fit-content '>
                    <FiPhone className='text-color-white' />
                  </div>
                  <div>
                    <h4 className='fs-4 fw-bold text-color-white text-capitalize'>Contact number</h4>
                    <p className="fs-6 text-color-white mb-1">+91-731-7968482 </p>
                    <p className="fs-6 text-color-white m-0">+91-731-7968482 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="py-4">
            <div className="row">
              <div className="col col-sm-12">
                <p className="font-size-14 text-color-white m-0">
                  *All trademarks, service marks, trade names, logos, icons, and
                  domain names featured on this website are the sole property of
                  MA Eyewear Private Limited. Unauthorized use, including but
                  not limited to the unauthorized use of Specxweb's trademarks,
                  service marks, trade names, logos, icons, and domain names,
                  without the express written consent of MA Eyewear Private
                  Limited, is strictly prohibited and may violate intellectual
                  property laws. Any third-party trademarks, logos, and service
                  marks displayed on this website are used either under license
                  or with the permission of their respective owners, based on
                  the assurance that such use does not infringe upon the
                  intellectual property rights of any third party. MA Eyewear
                  Private Limited diligently monitors the use of its
                  intellectual property and to the best of its knowledge,
                  ensures compliance with all legal standards, thus preventing
                  any infringement, unauthorized use, or misappropriation of
                  third-party intellectual property rights.{" "}
                </p>
                <div className=" d-sm-none border-bottom border-1  my-3" />

                <p className="font-size-14  text-color-white my-1">
                  <span className="fw-bold">
                    MA EYEWEAR PRIVATE LIMITED CIN
                  </span>{" "}
                  : U74999KA2016PTC097331
                </p>
                <p className="font-size-14  text-color-white my-1">
                  <span className="fw-bold">Registered Office</span> : UG 2A,
                  VIDHYAPATI COMPLEX, RACE COURSE ROAD, JANZEERWALA SQUARE,
                  INDORE, M.P., INDIA
                </p>
                <p className="font-size-14  text-color-white my-1">
                  <span className="fw-bold">Phone No</span> : 0731-7968482
                </p>
                <p className="font-size-14  text-color-white my-1">
                  <span className="fw-bold">Email Id</span> : info@specxweb.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom border-1 " />
        <div className="container">
          <div className="py-3">
            <div className="row flex-column flex-sm-row justify-content-between align-items-center">
              <div className="col col-sm-6">
                <p className=" m-0 text-color-white copyright-text">
                  ©{currentYear} All Rights Reserved By MA Eyewear Private
                  Limited{" "}
                </p>
              </div>
              <div className="col col-sm-3 terms-data">
                <div>
                  <ul className="terms-condition list-unstyled d-flex m-0 align-items-center justify-content-center">
                    <li className=" text-capitalize fw-normal border-1 border-end px-2">
                      {" "}
                      <Link
                        className="text-decoration-none text-color-white"
                        to={termsCondition}
                      >
                        Terms & Condition
                      </Link>
                    </li>
                    {/* <li className=' text-capitalize fw-normal border-1 border-end px-2'>  <Link className='text-decoration-none text-color-white' >Cookies</Link></li> */}
                    <li className=" text-capitalize fw-normal px-2">
                      {" "}
                      <Link
                        to={privacy}
                        className="text-decoration-none text-color-white"
                      >
                        privacy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AlertModal
          openModal={openModal}
          handleOpenCloseModal={handleOpenCloseModal}
          size="lg"
        >
          <FeedbackForm
            handleOpenCloseModal={handleOpenCloseModal}
            showThankYouPopUp={showThankYouPopUp}
            setShowThankYouPopUp={setShowThankYouPopUp}
            setOpenModal={setOpenModal}
            openModal={openModal}
          />
        </AlertModal>
      )}
      {showThankYouPopUp && (
        <AlertModal
          showThankYouPopUp={showThankYouPopUp}
          handleOpenCloseThankyouModal={handleOpenCloseThankyouModal}
          size="md"
        >
          <ThankYou
            showThankYouPopUp={showThankYouPopUp}
            setShowThankYouPopUp={setShowThankYouPopUp}
          />
        </AlertModal>
      )}
    </>
  );
};

export default Index;
