import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assests/css/common.scss';

import RouterManager from './routes/RouterManger'

const RootComponent = () => {
  return (
    <>
      <React.StrictMode>
        <RouterManager />
      </React.StrictMode>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

