import React, { useState } from 'react'
import * as Yup from 'yup'
import SectionTitle from '../sectionTitle'
import Input from '../appInput'
import { useFormik } from 'formik'
import { Rating } from 'react-simple-star-rating'
import { BsArrowRightShort } from 'react-icons/bs'
import { RxCrossCircled } from 'react-icons/rx'
import { useSubmitFeedbackDataMutation } from '../../../services/contactSlice'
const Index = ({ handleOpenCloseModal, setOpenModal, openModal, showThankYouPopUp, setShowThankYouPopUp }) => {
    const [rating, setRating] = useState(0)
    const [submitFeedback, { data: feedbackData, isLoading, isError, isSuccess: feedbackSucces, }] = useSubmitFeedbackDataMutation()
    const handleRating = (rate) => {
        setRating(rate)
    }

    const phoneRegex = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
    const formik = useFormik({
        initialValues: {
            customer_name: "",
            email: "",
            contact_number: "",
            invoice_number: "",
            store_name: "",
            rating: "",
            suggestion: ''
        },
        validationSchema: Yup.object().shape({
            customer_name: Yup.string(),
            email: Yup.string().email("enter a valid email").required('please enter your email'),
            contact_number: Yup.string().matches(phoneRegex, 'phone number is not valid'),
            invoice_number: Yup.string(),
            store_name: Yup.string(),
            rating: Yup.string(),
            suggestion: Yup.string()
        }),
        onSubmit: async (value) => {
            try {
                value.rating = rating
                await submitFeedback(value)
            } catch (error) {
                console.log(error)
            }
        }
    })


    return (
        <>
            {feedbackSucces && (setOpenModal(!openModal), setShowThankYouPopUp(!showThankYouPopUp))}
            <div id='feedbackForm' >
                <div className='d-flex align-items-top justify-content-between'>
                    <SectionTitle filledText={'Quick'} strokeText={"Feedback Form"} />
                    <RxCrossCircled size={45} onClick={handleOpenCloseModal} className='cursor-pointer' />
                </div>
                <form action="" className="form" onSubmit={formik.handleSubmit}>
                    <div className="row mt-3">
                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                            <Input
                                label={'Customer Name'}
                                placeholder={'Enter Full Name'}
                                name={'customer_name'}
                                type={'text'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.customer_name}
                                errorText={formik.errors.customer_name}
                                touched={formik.touched.customer_name}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                            <Input
                                label={'Email Address'}
                                placeholder={'Enter email address'}
                                name={'email'}
                                type={'email'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                errorText={formik.errors.email}
                                touched={formik.touched.email}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                            <Input
                                label={'Contact Number'}
                                placeholder={'Enter Contact Number'}
                                name={'contact_number'}
                                type={'text'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.contact_number}
                                errorText={formik.errors.contact_number}
                                touched={formik.touched.contact_number}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                            <Input
                                label={'Invoice Number'}
                                placeholder={'Enter Invoice Number'}
                                name={'invoice_number'}
                                type={'text'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.invoice_number}
                                errorText={formik.errors.invoice_number}
                                touched={formik.touched.invoice_number}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                            <Input
                                label={'Store Name'}
                                placeholder={'Select Store'}
                                name={'store_name'}
                                type={'text'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.store_name}
                                errorText={formik.errors.store_name}
                                touched={formik.touched.store_name}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 mb-3">
                            <h6 className='fs-6 text-color-black my-2 fw-bold'>Overall Experience</h6>
                            <div className='py-2'>
                                <Rating
                                    onClick={handleRating}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h6 className='fs-6 text-color-black my-2 fw-bold'>Any specific Thoughts or Suggestions</h6>
                            <textarea
                                name="suggestion"
                                rows="5"
                                className='w-100 textarea'
                                placeholder='Enter description'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur.suggestion}
                            />
                        </div>
                    </div>
                    <div className='text-center my-3'>
                        <button className='rounded-pill bg-color-primary text-color-white submit-btn-padding border-0 fs-5' type='submit'>Submit <BsArrowRightShort /></button>
                    </div>
                </form>
            </div>
        
        </>
    )
}

export default Index
