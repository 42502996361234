import React from "react";
import { CommonBannerImage } from '../../../assests/images'
import BannerSection from "../../../components/common/bannerSection";
import FaqBar from "../../../components/common/faqBar";
import { globalConstant } from "../../../constant/globalConstant";
const index = () => {
    return (
        <div>
            <BannerSection
                image={CommonBannerImage}
                bannerHeading={"FAQ"}
                subHeading={
                    "Feel free to customize and expand upon these questions and answers to better suit your specific FAQ needs."
                }
            />
            {/* FAQ section starts */}
            <div className="container-fluid bg-color-tertiary">
                <div className="bg-with-hexImage py-5">
                    <div className="container">
                        <div className="row mb-3 gy-3">
                            <div className="col-12 col-sm-12 col-md-6 ">
                                <div class="accordion" id="accordionExample">
                                    {globalConstant.FAQ1.map((item, idx) => {
                                        return (
                                            <FaqBar
                                                key={idx}
                                                id={item.id}
                                                question={item.question}
                                                answer={
                                                    item.answer
                                                }
                                            />
                                        )
                                    })}

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div class="accordion" id="accordionExample2">
                                    {globalConstant.FAQ2.map((item, idx) => {
                                        return (
                                            <FaqBar
                                                key={idx}
                                                accordionExample2={item.accordionExample2}
                                                id={item.id}
                                                question={item.question}
                                                answer={
                                                    item.answer
                                                }
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default index;
